import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { PortfolioModuleStyles } from "./PortfolioStyles"
import Portfolio from "../PortfolioModule/Portfolio"

const PortfoliosModule = () => {
  return (
    <PortfolioModuleStyles>
      <StaticImage
        className="Portfolios__image--bg"
        src="../../images/abstract-building.jpg"
        alt="Portfolios Module"
        layout="constrained"
        placeholder="tracedSVG"
      />
      <div className="Portfolios__image--overlay"></div>
      <div className="container">
        <Portfolio
          title="Parity Recruitment"
          link="https://parity.net/"
          content="Fully custom Job Board back-end integration for public company."
        >
          <StaticImage
            src="../../images/parity.svg"
            alt="Portfolio Image"
            layout="constrained"
            placeholder="tracedSVG"
          />
        </Portfolio>
          <Portfolio
          title="PictureThisTV"
          link="https://picturethistv.co.uk/"
          content="My first independently built commercial-grade website."
        >
          <StaticImage
            src="../../images/logos/picturethis-logo.svg"
            alt="Portfolio Image"
            layout="constrained"
            placeholder="tracedSVG"
          />
        </Portfolio>
        <Portfolio
          title="Tactical Training College"
          link='https://tacticaltrainingcollege.com/'
          content="Solo built SEO website, optimised for User Experience and speed."
        >
          <StaticImage
            src="../../images/logos/ttc-logo.svg"
            alt="Portfolio Image"
            layout="constrained"
            placeholder="tracedSVG"
          />
        </Portfolio>
        <Portfolio
          title="3SFire"
          link="https://3sfire.co.uk/"
          content="Brand new website for fire safety company 3SFire. Built by me, from scratch."
        >
          <StaticImage
            src="../../images/3SFire-logo-white.png"
            alt="Portfolio Image"
            layout="constrained"
            placeholder="tracedSVG"
          />
        </Portfolio>
      </div>
    </PortfolioModuleStyles>
  )
}

export default PortfoliosModule
