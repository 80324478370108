import * as React from "react"
import { PortfolioStyles } from "./PortfolioStyles"

const Portfolio = ({ children, title, content, link }) => {
  return (
    <PortfolioStyles>
      {children}
      {title && <a href={link}><h2>{title}</h2></a>}
      {content && <p>{content}</p>}
    </PortfolioStyles>
  )
}

export default Portfolio
