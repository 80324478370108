import * as React from "react"
import Button from "../Button/Button"
import { BasicTextModuleStyles } from "./BasicTextModuleStyles"
import { Link } from "gatsby"

const BasicTextModule = () => {
  return (
    <BasicTextModuleStyles>
      <div className="container">
        <h2>
          About Me ?
        </h2>
        <p style={{ marginBottom: "20px" }}>
          I am a passionate Web Developer currently working at <a href="https://www.sidigital.co">Si Digital</a>. I have been building on my current knowledge and expanding on my skills in areas including web development, building, maintenance, scaling,and supporting.
        </p>

        <p style={{ marginBottom: "60px" }}>
          My passion and determination to do the things I love set me apart, and ensure I give 100% in all my projects. My ultimate goal is to learn a little bit about everything digital, one step at a time!        </p>
        <Button text="View Blog" as={Link} to="/blogs" />
      </div>
    </BasicTextModuleStyles>
  )
}

export default BasicTextModule
