import * as React from "react"
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import BannerModule from "../components/BannerModule/BannerModule"
import BasicTextModule from "../components/BasicTextModule/BasicTextModule"
import PortfolioModule from "../components/PortfolioModule/PortfolioModule"


const Index = () => {
  return (
    <>
      <Seo title="Home" />
      <Layout>
        <BannerModule
          title="Alfie Mills"
          subTitle="Web Development. Server Deployment. Network Technician"
        />
        <BasicTextModule />
        <PortfolioModule />
      </Layout>
    </>
  )
}

export default Index
